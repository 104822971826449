import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCableContext } from 'src/providers/ActionCableProvider'
import { setHasNews } from 'src/redux/notfication/getNotifications'

const useNewsListChannel = () => {
  const cable = useContext(ActionCableContext)
  const dispatch = useDispatch()
  const [newsMessage, setNewsMessage] = useState()

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create(
      { channel: 'NewsListChannel' },
      {
        received: (msg) => {
          setNewsMessage(msg)
          dispatch(setHasNews(Boolean(msg?.is_have_news)))
        }
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [cable])

  return newsMessage
}

export default useNewsListChannel
