import Background from 'src/assets/img/message/detail/bg-page.png'

import IconSora from 'src/assets/img/menu/icon_sora.png'
import IconMinato from 'src/assets/img/menu/icon_minato.png'
import IconRen from 'src/assets/img/menu/icon_ren.png'
import IconSho from 'src/assets/img/menu/icon_sho.png'
import IconSyun from 'src/assets/img/menu/icon_syun.png'
import IconKei from 'src/assets/img/menu/icon_kei.png'
import IconSakura from 'src/assets/img/menu/icon_sakura.png'
import IconJin from 'src/assets/img/menu/icon_jin.png'
import IconItsuki from 'src/assets/img/menu/icon_itsuki.png'
import IconNagi from 'src/assets/img/menu/icon_nagi.png'
import IconManager from 'src/assets/img/menu/icon_manager.png'

import ProfileAsahi from 'src/assets/img/character/profile/profile_asahisoa.png'
import ProfileKazehaya from 'src/assets/img/character/profile/profile_kazehayasho.png'
import ProfileTachibana from 'src/assets/img/character/profile/profile_tachibanashun.png'
import ProfileWakatsuki from 'src/assets/img/character/profile/profile_wakatsukiren.png'
import ProfileYokohama from 'src/assets/img/character/profile/profile_yokohamaminato.png'

import RoomAsahi from 'src/assets/img/room/default_asahi_sora.png'
import RoomYokohama from 'src/assets/img/room/default_yokohama_minato.png'
import RoomWakatuki from 'src/assets/img/room/default_wakatuki_ren_update.png'
import RoomKazehaya from 'src/assets/img/room/default_kazehaya_sho.png'
import RoomTatibana from 'src/assets/img/room/default_tatibana_shun_update.png'
import MypageAsahi from 'src/assets/img/character/mypage/asahi_sora.png'
import MypageYokohama from 'src/assets/img/character/mypage/yokohama_minato.png'
import MypageWakatuki from 'src/assets/img/character/mypage/wakatuki_ren_update.png'
import MypageKazehaya from 'src/assets/img/character/mypage/kazehaya_sho.png'
import MypageTatibana from 'src/assets/img/character/mypage/tatibana_shun_update.png'

import voiceSora from 'src/assets/img/mypage/recommend/sora_voice.wav'
import voiceMinato from 'src/assets/img/mypage/recommend/minato_voice.wav'
import voiceSho from 'src/assets/img/mypage/recommend/sho_voice.wav'
import voiceSyun from 'src/assets/img/mypage/recommend/syun_voice.wav'
import voiceRen from 'src/assets/img/mypage/recommend/ren_voice.wav'
import voiceItsuki from 'src/assets/img/mypage/recommend/itsuki_voice.wav'
import voiceJin from 'src/assets/img/mypage/recommend/jin_voice.wav'
import voiceNagi from 'src/assets/img/mypage/recommend/nagi_voice.wav'
import voiceSakura from 'src/assets/img/mypage/recommend/sakura_voice.wav'

import recommendSora from 'src/assets/img/mypage/recommend/bnr_sora.png'
import recommendSho from 'src/assets/img/mypage/recommend/bnr_sho.png'
import recommendKei from 'src/assets/img/mypage/recommend/bnr_kei.png'
import recommendSakura from 'src/assets/img/mypage/recommend/bnr_sakura.png'
import recommendJin from 'src/assets/img/mypage/recommend/bnr_jin.png'
import recommendNagi from 'src/assets/img/mypage/recommend/bnr_nagi.png'
import recommendItsuki from 'src/assets/img/mypage/recommend/bnr_itsuki.png'
import recommendSyun from 'src/assets/img/mypage/recommend/bnr_syun.png'
import recommendRen from 'src/assets/img/mypage/recommend/bnr_ren.png'
import recommendMinato from 'src/assets/img/mypage/recommend/bnr_minato.png'

import talentIconSora from 'src/assets/img/mypage/recommend/icon_sora.png'
import talentIconMinato from 'src/assets/img/mypage/recommend/icon_minato.png'
import talentIconSho from 'src/assets/img/mypage/recommend/icon_sho.png'
import talentIconSyun from 'src/assets/img/mypage/recommend/icon_syun.png'
import talentIconNagi from 'src/assets/img/mypage/recommend/icon_nagi.png'
import talentIconKei from 'src/assets/img/mypage/recommend/icon_kei.png'
import talentIconSakura from 'src/assets/img/mypage/recommend/icon_sakura.png'
import talentIconJin from 'src/assets/img/mypage/recommend/icon_jin.png'
import talentIconItsuki from 'src/assets/img/mypage/recommend/icon_itsuki.png'
import talentIconRen from 'src/assets/img/mypage/recommend/icon_ren.png'

export const characterNames = ['朝日空', '風早翔', '立花駿', '若槻蓮', '横浜湊']
export const CHARACTER_NAVIGATOR_ID = 6

// TODO: Add new characters and Image to this list.
export const CHARACTERS = [
  {
    name: '朝日空',
    avatar: IconSora,
    background: Background,
    id: 1,
    groupName: 'D6'
  },
  {
    name: '横浜湊',
    avatar: IconMinato,
    background: Background,
    id: 2,
    groupName: 'D6'
  },
  {
    name: '若槻蓮',
    avatar: IconRen,
    background: Background,
    id: 3,
    groupName: 'D6'
  },
  {
    name: '風早翔',
    avatar: IconSho,
    background: Background,
    id: 4,
    groupName: 'D6'
  },
  {
    name: '立花駿',
    avatar: IconSyun,
    background: Background,
    id: 5,
    groupName: 'D6'
  },
  {
    name: '彩音慶',
    background: Background,
    id: 7,
    groupName: 'solo',
    avatar: IconKei
  },
  {
    name: '九条咲良',
    background: Background,
    id: 8,
    groupName: '劇団J’s',
    avatar: IconSakura
  },
  {
    name: '来栖仁',
    background: Background,
    id: 9,
    avatar: IconJin,
    groupName: '劇団J’s'
  },
  {
    name: '折原樹',
    background: Background,
    id: 10,
    avatar: IconItsuki,
    groupName: '蒼生演舞'
  },
  {
    name: '飛鳥凪',
    background: Background,
    id: 11,
    avatar: IconNagi,
    groupName: '蒼生演舞'
  }
]

export const CHARACTER_LINE_LINKS = {
  1: process.env.REACT_APP_CHARACTER_1_LINE_LINK,
  2: process.env.REACT_APP_CHARACTER_2_LINE_LINK,
  3: process.env.REACT_APP_CHARACTER_3_LINE_LINK,
  4: process.env.REACT_APP_CHARACTER_4_LINE_LINK,
  5: process.env.REACT_APP_CHARACTER_5_LINE_LINK,
  6: process.env.REACT_APP_CHARACTER_6_LINE_LINK,
  7: process.env.REACT_APP_CHARACTER_7_LINE_LINK,
  8: process.env.REACT_APP_CHARACTER_8_LINE_LINK,
  9: process.env.REACT_APP_CHARACTER_9_LINE_LINK,
  10: process.env.REACT_APP_CHARACTER_10_LINE_LINK,
  11: process.env.REACT_APP_CHARACTER_11_LINE_LINK
}

export const CIRCLE_IMAGE_OF_CHARACTER = {
  1: IconSora,
  2: IconMinato,
  3: IconRen,
  4: IconSho,
  5: IconSyun,
  6: IconManager,
  7: IconKei,
  8: IconSakura,
  9: IconJin,
  10: IconItsuki,
  11: IconNagi
}

export const CHARACTER_PROFILE_IMAGE = {
  1: ProfileAsahi,
  2: ProfileYokohama,
  3: ProfileWakatsuki,
  4: ProfileKazehaya,
  5: ProfileTachibana
}

export const MYPAGE_CHARACTER_IMAGES = {
  1: { image: MypageAsahi, room: RoomAsahi },
  2: { image: MypageYokohama, room: RoomYokohama },
  3: { image: MypageWakatuki, room: RoomWakatuki },
  4: { image: MypageKazehaya, room: RoomKazehaya },
  5: { image: MypageTatibana, room: RoomTatibana }
}

export const SCHEDULE_CHARACTER_IMAGES = {
  1: { name: '朝日空', color: 'bg-[#e45e57]' },
  2: { name: '横浜湊', color: 'bg-[#9747ff]' },
  3: { name: '若槻蓮', color: 'bg-[#fa9901]' },
  4: { name: '風早翔', color: 'bg-[#30c54d]' },
  5: { name: '立花駿', color: 'bg-[#f791cc]' },
  7: { name: '彩音慶', color: 'bg-[#CDB8E8]' },
  8: { name: '九条咲良', color: 'bg-[#DBCC45]' },
  9: { name: '来栖仁', color: 'bg-[#826985]' },
  10: { name: '折原樹', color: 'bg-[#00C5DF]' },
  11: { name: '飛鳥凪', color: 'bg-[#0CD0FB]' }
}

export const RECOMMEND_TALENT = [
  {
    id: 1,
    name: '朝日空',
    englishName: 'Sora Asahi',
    group: 'dx6',
    description: `君の笑顔が見たいから。
      いつでも君に
      エネルギーチャージ！
      D×6リーダー、朝日空`,
    age: '18',
    birthdate: '8月20日',
    bloodType: 'A',
    height: '176',
    zodiac: '獅子',
    fullImage: recommendSora,
    icon: talentIconSora,
    cv: 'オノデラシズク',
    voice: voiceSora
  },
  {
    id: 2,
    name: '横浜湊',
    group: 'dx6',
    englishName: 'Minato Yokohama',
    description:
      `満足するまで尽くして見せよう
      姫のため
      あなたの心に百花繚乱 横浜湊`,
    age: '18',
    birthdate: '6月23日',
    bloodType: 'B',
    height: '178',
    zodiac: '蟹子',
    fullImage: recommendMinato,
    icon: talentIconMinato,
    cv: '大城盛東',
    voice: voiceMinato
  },
  {
    id: 3,
    name: '若槻蓮',
    group: 'dx6',
    englishName: 'Ren Wakatsuki',
    description:
      `チャラくまじめに！生まれた時
      からモテ期が止まらない！
      ファンタジスタ★若槻蓮。`,
    age: '18',
    birthdate: '9月21日',
    bloodType: 'O',
    height: '175',
    zodiac: '乙女',
    fullImage: recommendRen,
    icon: talentIconRen,
    cv: '長濱有太朗',
    voice: voiceRen
  },
  {
    id: 4,
    name: '風早翔',
    group: 'dx6',
    englishName: 'Sho Kazehaya',
    description:
      `シンデレラの君に。
      眠らずに見る夢を
      おもてなしの貴公子 風早翔`,
    age: '19',
    birthdate: '4月19日',
    bloodType: 'AB',
    height: '180',
    zodiac: '牡羊',
    fullImage: recommendSho,
    icon: talentIconSho,
    cv: '佐藤悠斗',
    voice: voiceSho
  },
  {
    id: 5,
    name: '立花駿',
    group: 'dx6',
    englishName: 'Syun Tachibana',
    description:
      `世界中の人を歌で笑顔に！
      顔面偏差値75♪
      D×6の天真爛漫 立花駿♪`,
    age: '18',
    birthdate: '11月22日',
    bloodType: 'O',
    height: '174',
    zodiac: 'さそり',
    fullImage: recommendSyun,
    icon: talentIconSyun,
    cv: '平畑栄樹',
    voice: voiceSyun
  },
  {
    id: 7,
    name: '彩音慶',
    group: 'solo',
    englishName: 'Kei Ayane',
    description:
      `響け、声。届け、想い。
      いつだって俺の声で、
      キミの心を満たしてあげる。`,
    age: '28',
    birthdate: '12月14日',
    bloodType: 'A',
    height: '176',
    zodiac: '射手',
    fullImage: recommendKei,
    icon: talentIconKei
  },
  {
    id: 8,
    name: '九条咲良',
    group: 'theater_groupej',
    englishName: 'Sakura Kujyo',
    description:
      `今日はキミと、
      最高のステージを。
      劇団J's、九条咲良`,
    age: '26',
    birthdate: '3月31日',
    bloodType: 'O',
    height: '178',
    job: '役者/演出家',
    fullImage: recommendSakura,
    icon: talentIconSakura,
    voice: voiceSakura
  },
  {
    id: 9,
    name: '来栖仁',
    group: 'theater_groupej',
    englishName: 'Jin Kurusu',
    description:
      `天下御免と参上致すは
      俺のこと。
      劇団J's、来栖仁だ。`,
    age: '26',
    birthdate: '7月26日',
    bloodType: 'A',
    height: '182',
    job: '役者/脚本家',
    fullImage: recommendJin,
    icon: talentIconJin,
    voice: voiceJin
  },
  {
    id: 10,
    name: '折原樹',
    group: 'aoi_dance',
    englishName: 'Itsuki Orihara',
    description:
      `ほら、顔をあげて？
      俺と花道を歩く心の準備、
      出来てるよね♪
      よそ見なんてさせない、
      ずっと俺達だけを見てなよ。`,
    age: '26',
    birthdate: '3月31日',
    bloodType: 'O',
    height: '178',
    job: 'ダンサー/舞台役者/脚本家',
    fullImage: recommendItsuki,
    icon: talentIconItsuki,
    voice: voiceItsuki
  },
  {
    id: 11,
    name: '飛鳥凪',
    group: 'aoi_dance',
    englishName: 'Nagi Asuka',
    description:
      `さぁ、俺の手を取って？
      凪兄さんが、キミが見た事の
      ない景色を見せてあげる♪`,
    age: '23',
    birthdate: '2月27日',
    bloodType: 'AB',
    height: '178',
    job: '役者ダンサー/舞台役者/脚本家',
    fullImage: recommendNagi,
    icon: talentIconNagi,
    voice: voiceNagi
  }
]
