import { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useAuthState } from 'src/providers/AuthProviders'

import { HistoryRouteContext } from 'src/routes'
import { HeaderHeart } from 'src/components/common/HeaderHeart'
import { MAIN_PATHS } from 'src/routes/main'

import life from 'src/assets/img/common/life.png'
import plus from 'src/assets/img/common/plus.png'
import diamondRed from 'src/assets/img/common/diamond-red.png'
import diamondBlue from 'src/assets/img/common/diamond-blue.png'
import { useSelector } from 'react-redux'

export const Header = ({ className = 'shadow-custom backdrop-blur-sm' }) => {
  const { header } = useSelector((state) => state.displayLayout)
  const { user } = useAuthState()
  const { redirectTo } = useContext(HistoryRouteContext)

  const DIAMOND_LINKS = [
    {
      image: life,
      value: user?.possession_heart.toLocaleString(),
      type: 'life'
    },
    {
      image: diamondBlue,
      value: user?.blue_amount.toLocaleString(),
      type: 'diamonBlue'
    },
    {
      image: diamondRed,
      value: user?.red_amount.toLocaleString(),
      type: 'diamonRed'
    }
  ]

  return (
    <div
      className={clsx(
        `${
          header ? 'h-[42px] top-0' : 'h-0 -top-32'
        } w-full animation-expand sm:w-desktop-width fixed z-50 flex items-start`,
        className
      )}
      style={{ background: 'rgba(255, 255, 255, 0.3)' }}
    >
      <HeaderHeart />
      <div className="grid grid-cols-3 gap-3 items-center h-full right-0 flex-1 pr-3">
        {DIAMOND_LINKS.map((link, index) => (
          <div key={index} className="rounded-sm bg-white transform skew-x-[-20deg] shadow-custom">
            <div className="flex flex-row items-center justify-between px-1 skew-x-[20deg]">
              <div className="flex flex-row items-center gap-0.5">
                <img src={link.image} className="w-4" />
                <div className="text-[13px]">{link.value}</div>
              </div>
              {link.type !== 'diamonBlue' && (
                <div
                  onClick={() =>
                    redirectTo(link.type === 'life' ? MAIN_PATHS.ShopPageSwap : MAIN_PATHS.ShopPage)
                  }
                  className="cursor-pointer"
                >
                  <img src={plus} alt="plus" className="h-3" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}
