import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'

import Item, { ITEM_TYPE } from 'src/models/Item'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import ItemListButton from 'src/components/menu/ItemListButton'
import NoContent from 'src/components/layouts/NoContent'
import ItemDetailsModal from 'src/pages/menu/ItemDetailsModal'

const TabContent = ({ isSelected, content }) => {
  return (
    <div
      className={clsx(
        'text-xs w-full py-2 rounded-t-2xl font-bold',
        isSelected ? 'bg-white text-light-pink' : 'bg-menu-gray text-white'
      )}
    >
      <p>{content}</p>
    </div>
  )
}

export const ItemPage = ({ itemsHistory, isLoading, itemSpecial }) => {
  const [showModal, setShowModal] = useState(false)
  const [modalItem, setModalItem] = useState(null)
  const [specialItems, setSpecialItems] = useState([])

  const handleModal = (item) => {
    setModalItem(item)
    setShowModal(true)
  }

  const handleClose = () => {
    setModalItem(null)
    setShowModal(false)
  }
  useEffect(() => {
    if (!itemSpecial || itemSpecial.length === 0) {
      setSpecialItems([])
    } else {
      setSpecialItems(
        itemSpecial.filter(
          (el) =>
            (el.type === ITEM_TYPE.Special &&
              (el.itemKind === 'gacha_ticket' || el.itemKind === 'ssr_ticket')) ||
            (el.type === ITEM_TYPE.Payment && el.itemKind === 'platinum_passport')
        )
      )
    }
  }, [itemSpecial])

  return (
    <>
      <MainLayout active="menu" classNameHeader={''}>
        <MenuWrapper className="flex flex-col" overflow={true}>
          <SubmenuHeader title={'所持アイテム'} />
          <Tab.Group>
            <Tab.List className="flex justify-between bg-light-gray pt-2 px-4 gap-1">
              <Tab className="w-full">
                {({ selected }) => <TabContent isSelected={selected} content="アイテム" />}
              </Tab>
              <Tab className="w-full">
                {({ selected }) => <TabContent isSelected={selected} content="特殊アイテム" />}
              </Tab>
            </Tab.List>
            <Tab.Panels
              className="bg-light-gray px-4"
              style={{
                height: 'calc(100dvh - 220px)'
              }}
            >
              <Tab.Panel className={clsx('bg-white h-[97%] overflow-y-scroll hidden-scroll')}>
                <div
                  className={`${
                    itemsHistory.length > 0 ? 'grid grid-cols-5 gap-1' : 'flex items-center h-full'
                  }`}
                >
                  {itemsHistory.length > 0 ? (
                    itemsHistory.map((item, index) => (
                      <ItemListButton
                        countItem={item.count}
                        key={index}
                        image={item?.image?.url}
                        name={item.name}
                        onClick={() => {
                          handleModal(item)
                        }}
                        type="standard"
                        rarity
                      />
                    ))
                  ) : (
                    <NoContent
                      message={
                        isLoading ? 'ただいま、読み込み中です' : 'お持ちの\nアイテムがありません'
                      }
                    />
                  )}
                </div>
              </Tab.Panel>
              <Tab.Panel className={clsx('bg-white h-[97%] overflow-y-scroll hidden-scroll')}>
                <div
                  className={`${
                    specialItems.length > 0 ? 'grid grid-cols-5 gap-1' : 'flex items-center h-full'
                  }`}
                >
                  {specialItems.length > 0 ? (
                    specialItems.map(
                      (item, index) =>
                        (item.amount > 0 || item.itemKind === 'platinum_passport') && (
                          <ItemListButton
                            key={index}
                            image={item.image.url}
                            name={item.name}
                            onClick={() => {
                              handleModal(item)
                            }}
                            rarity
                            countItem={item.itemKind === 'platinum_passport' ? 1 : item.amount}
                          />
                        )
                    )
                  ) : (
                    <NoContent
                      message={
                        isLoading ? 'ただいま、読み込み中です' : 'お持ちの\nアイテムがありません'
                      }
                    />
                  )}
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          {showModal && (
            <ItemDetailsModal item={modalItem} handleClose={handleClose} open={showModal} />
          )}
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

ItemPage.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)),
  isLoading: PropTypes.bool.isRequired,
  itemsHistory: PropTypes.array,
  itemSpecial: PropTypes.array
}

TabContent.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired
}
