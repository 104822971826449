import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const readNewsByPage = async (page) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.News.readAll, {
      params: {
        page: page
      }
    })
    if (res.data.success === false) return false

    return res.data
  } catch (error) {
    console.log(error)
    return false
  }
}

export default readNewsByPage
