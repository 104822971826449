import SoraLove from 'src/assets/img/realtalk/sora_top.webp'
import MinatoLove from 'src/assets/img/realtalk/minato_top.webp'
import RenLove from 'src/assets/img/realtalk/ren_top.webp'
import ShoLove from 'src/assets/img/realtalk/sho_top.webp'
import SyunLove from 'src/assets/img/realtalk/syun_top.webp'
import Sora from 'src/assets/img/realtalk/sora_bg.svg'
import SyunSub from 'src/assets/img/realtalk/suyn_bg.svg'
import ShoSub from 'src/assets/img/realtalk/sho-bg.svg'
import RenSub from 'src/assets/img/realtalk/ren_bg.svg'
import MinatoSub from 'src/assets/img/realtalk/minato_bg.svg'
import SoraBtn from 'src/assets/img/realtalk/button_sora.png'
import SyunBtn from 'src/assets/img/realtalk/button_syun.png'
import ShoBtn from 'src/assets/img/realtalk/button_sho.png'
import RenBtn from 'src/assets/img/realtalk/button_ren.png'
import MinatoBtn from 'src/assets/img/realtalk/button_minato.png'
import iconCharacterMina from 'src/assets/img/realtalk/realtalk_sd_minato.png'
import iconCharacterRen from 'src/assets/img/realtalk/realtalk_sd_ren.png'
import iconCharacterSho from 'src/assets/img/realtalk/realtalk_sd_sho.png'
import iconCharacterSora from 'src/assets/img/realtalk/realtalk_sd_sora.png'
import iconCharacterSyun from 'src/assets/img/realtalk/realtalk_sd_syun.png'
import SoraLeft from 'src/assets/img/gacha/sora_left.png'
import MinatoLeft from 'src/assets/img/gacha/minato_left.png'
import SyunLeft from 'src/assets/img/gacha/syun_left.png'
import RenLeft from 'src/assets/img/gacha/ren_left.png'
import ShoLeft from 'src/assets/img/gacha/sho_left.png'

const ASHA = '朝日空'
const MINA = '横浜湊'
const REN = '若槻蓮'
const SHO = '風早翔'
const SYUN = '立花駿'

const characterConfig = {
  [ASHA]: {
    imageActive: SoraLove,
    imageGray: Sora,
    imgIcon: iconCharacterSora,
    imgButton: SoraBtn,
    imgLeft: SoraLeft
  },
  [MINA]: {
    imageActive: MinatoLove,
    imageGray: MinatoSub,
    imgIcon: iconCharacterMina,
    imgButton: MinatoBtn,
    imgLeft: MinatoLeft
  },
  [REN]: {
    imageActive: RenLove,
    imageGray: RenSub,
    imgIcon: iconCharacterRen,
    imgButton: RenBtn,
    imgLeft: RenLeft
  },
  [SHO]: {
    imageActive: ShoLove,
    imageGray: ShoSub,
    imgIcon: iconCharacterSho,
    imgButton: ShoBtn,
    imgLeft: ShoLeft
  },
  [SYUN]: {
    imageActive: SyunLove,
    imageGray: SyunSub,
    imgIcon: iconCharacterSyun,
    imgButton: SyunBtn,
    imgLeft: SyunLeft
  }
}

export const characterSlide = (data) => {
  if (!data || !Array.isArray(data) || data.length === 0) {
    return
  }
  return data.map((item) => {
    const config = characterConfig[item?.name]
    return config ? { ...item, ...config } : item
  })
}
