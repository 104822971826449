import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { formatDateRange } from 'src/lib/date'
import useLoading from 'src/components/layouts/hooks/useLoading'

import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import { MENU_PATHS } from 'src/routes/menu'
import SectionHeader from 'src/components/menu/SectionHeader'
import EmailEditBox from 'src/components/menu/EmailEditBox'
import ModalSuccess from 'src/components/common/ModalSuccess'
import ModalCommon from 'src/components/common/ModalCommon'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import UserNameEditBox from 'src/components/menu/UserNameEditBox'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import { Wrapper } from 'src/components/common/Wrapper'

import Edit from 'src/assets/img/common/edit_icon.png'

const InfoBox = ({ title, label, info, additionalInfo, onClick }) => (
  <>
    <SectionHeader title={title} className="border-b w-full border-candy-pink" />
    <div className="flex flex-col ml-12 my-2 gap-1.5 items-start">
      <span className="text-xl">{info}</span>
      {label && (
        <button
          onClick={onClick}
          className="px-1.5 rounded-md bg-candy-pink text-white text-[0.5625rem] flex items-center gap-1"
        >
          {label} <img src={Edit} alt="edit" className="mb-[1px] w-2" />
        </button>
      )}
      {additionalInfo}
    </div>
  </>
)

const UserProfilePage = ({ user, handleSave, isLoading, apiErrors = {} }) => {
  const [showEditUserNameModal, setShowEditUserNameModal] = useState(false)
  const [showEditEmailModal, setShowEditEmailModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [editUserName, setEditUserName] = useState(user?.name || '')
  const [email, setEmail] = useState(user?.email || '')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [errors, setErrors] = useState({})
  const [buttons, setButtons] = useState([])
  const { showLoading, progress } = useLoading([isLoading])

  const genBirthdayString = () => formatDateRange(user?.birthday)

  useEffect(() => {
    setErrors((prevErrors) => ({ ...prevErrors, ...apiErrors }))
  }, [apiErrors])

  useEffect(() => {
    if (showEditUserNameModal) {
      setButtons([
        {
          type: 'close',
          label: 'キャンセル',
          onClick: () => setShowEditUserNameModal(false)
        },
        {
          label: '変更する',
          onClick: handleUserNameSave,
          disabled: !editUserName || editUserName.length < 1
        }
      ])
    }
  }, [showEditUserNameModal, editUserName])

  useEffect(() => {
    if (showEditEmailModal) {
      setButtons([
        { type: 'close', label: 'キャンセル', onClick: () => setShowEditEmailModal(false) },
        {
          label: '変更する',
          onClick: handleEmailSave,
          disabled: !email || !password || !passwordConfirm || Object.keys(errors).length > 0
        }
      ])
    }
  }, [showEditEmailModal, email, password, passwordConfirm, errors])

  const handleUserNameSave = () => {
    setShowEditUserNameModal(false)
    handleSave({ name: editUserName })
  }

  const handleEmailSave = async () => {
    const success = await handleSave({ email, password, password_confirmation: passwordConfirm })
    setShowEditEmailModal(!success)
    setShowSuccessModal(success)
  }

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      <MainLayout className="bg-menu-bg" active="menu" classNameHeader="">
        <MenuWrapper className="flex flex-col items-center" overflow={true}>
          <SubmenuHeader title="ID確認・名前変更" />
          <div className="bg-white px-6 w-full rounded font-Inter flex flex-col items-start overflow-y-auto text-candy-pink text-xs font-semibold hidden-scroll h-[calc(100dvh-200px)]">
            <InfoBox info={user?.id?.toString()} title="ユーザーID" />
            <InfoBox
              info={user?.name}
              title="名前"
              label="名前を変更する"
              onClick={() => setShowEditUserNameModal(true)}
            />
            <InfoBox
              info={genBirthdayString()}
              title="生年月日"
              additionalInfo={
                <span className="text-[0.5625rem] text-left">
                  ※生年月日は変更できません。生年月日を変更したい場合
                  <Link
                    to={MENU_PATHS.InquiryPage}
                    className="text-white bg-candy-pink py-[1px] px-0.5 rounded-[0.25rem]"
                  >
                    お問い合わせ
                  </Link>
                  までご連絡ください。
                </span>
              }
            />
            <InfoBox
              info={user?.email || '未設定'}
              title="メールアドレス"
              label={user?.email ? 'アドレスを変更する' : 'アドレスを登録する'}
              onClick={() => setShowEditEmailModal(true)}
              additionalInfo={
                <span className="text-[0.5625rem] text-left whitespace-pre-line">
                  {user?.email
                    ? '※safariやchromeでログインする場合、コチラがログインIDとなります。'
                    : '※メールアドレスを登録すると、safariやchromeでのログインが可能となります。'}
                </span>
              }
            />
            <InfoBox
              title="パスワード"
              info={user?.email ? '***********' : '未設定'}
              label={user?.email && 'パスワードを変更する'}
              onClick={() => setShowEditEmailModal(true)}
              additionalInfo={
                user?.email && (
                  <span className="text-xs text-left">
                    ※パスワードの初期設定はランダム値です。パスワードの変更・またはお忘れの方はパスワード変更へお進みください。
                  </span>
                )
              }
            />
          </div>
        </MenuWrapper>
        <ModalCommon
          title="名前変更"
          open={showEditUserNameModal}
          onClose={() => setShowEditUserNameModal(false)}
          theme="pink"
          buttons={buttons}
        >
          <UserNameEditBox
            errors={errors}
            setErrors={setErrors}
            editUserName={editUserName}
            setEditUserName={setEditUserName}
          />
        </ModalCommon>
        <ModalCommon
          title="メールアドレス変更"
          open={showEditEmailModal}
          onClose={() => setShowEditEmailModal(false)}
          theme="pink"
          buttons={buttons}
        >
          <EmailEditBox
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            passwordConfirm={passwordConfirm}
            setPasswordConfirm={setPasswordConfirm}
            errors={errors}
            setErrors={setErrors}
          />
        </ModalCommon>
        <ModalSuccess
          isOpen={showSuccessModal}
          text={`アドレスに確認メールを送りました！\nURLからログインしてくださいー!`} // eslint-disable-line quotes
          handleClose={() => setShowSuccessModal(false)}
        />
      </MainLayout>
    </>
  )
}

UserProfilePage.propTypes = {
  user: PropTypes.object.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiErrors: PropTypes.object
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  label: PropTypes.string,
  additionalInfo: PropTypes.node,
  onClick: PropTypes.func
}

export default UserProfilePage
