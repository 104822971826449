import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import useLoading from 'src/components/layouts/hooks/useLoading'

import { useHeartExchangeList } from 'src/pages/main/shop/hooks/useHeartExchangeQuery'
import { useShopQuery } from 'src/pages/main/shop/hooks/useShopQuery'
import { useAuthState } from 'src/providers/AuthProviders'

import Loading from 'src/components/layouts/Loading'
import ShopPurchaseResultScreen from 'src/pages/main/shop/components/ShopDiamondTab/ShopPurchaseResultScreen'
import { ShopContextProvider } from 'src/pages/main/shop/contexts/ShopContext'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import ShopDiamondTab from 'src/pages/main/shop/components/ShopDiamondTab'
import ShopHeartTab from 'src/pages/main/shop/components/ShopHeartTab'
import TipsModal from 'src/components/common/TipsModal'
import ColorHeader from 'src/components/common/ColorHeader'
import ModalCommon from 'src/components/common/ModalCommon'
import { Modal } from 'src/components/common/Modal'

import { MAIN_PATHS } from 'src/routes/main'

const ShopWrapper = styled.div.attrs({
  className: ''
})`
  height: calc(100dvh - 85px);
`

const ShopPage = ({ tab }) => {
  const navigate = useNavigate()
  const [showLoading, setShowLoading] = useState(false)
  const [modalType, setModalType] = useState('window')
  const [title, setTitle] = useState()
  const [buttons, setButtons] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(tab === 'swap' ? 1 : 0)
  const [modalContent, setModalContent] = useState(null)
  const [selectedItem, setSelectedItem] = useState()
  const closeModal = useCallback(() => setModalContent(null), [])
  const [selectedHeartExchange, setSelectedHeartExchange] = useState(null)
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('order_id')
  const targetScreen = searchParams.get('target_screen')

  const tabList = [
    {
      index: 0,
      title: '購入',
      hasNotification: false,
      textStyle: 'text-base leading-[1rem] py-0.5',
      activeTextStyle: 'background: linear-gradient(90deg, #FF75C0 0%, #FF2B9E 100%)',
      width: 'w-20 z-10',
      disabledText: 'text-[#FF92CD]'
    },
    {
      index: 1,
      title: '交換所',
      hasNotification: false,
      textStyle: 'text-base leading-[1rem] py-0.5',
      activeTextStyle: 'background: linear-gradient(90deg, #FF75C0 0%, #FF2B9E 100%)',
      width: 'w-20 z-10',
      disabledText: 'text-[#FF92CD]'
    }
  ]

  useEffect(() => {
    if (targetScreen !== 'PAYMENT_RESULT' || !orderId) return

    setModalContent(<ShopPurchaseResultScreen />)
    setModalType('window')
    setTitle('確認')
    setButtons([
      {
        label: 'OK',
        onClick: closeModal
      }
    ])
  }, [targetScreen])

  const handleChangeTab = (tabIndex) => {
    navigate(`/shop/${tabIndex === 1 ? 'swap' : 'buy'}`, { replace: true })
    setSelectedIndex(tabIndex)
  }

  const { pathname } = useLocation()
  const { data: swapData, isLoading: isLoadingSwap } = useHeartExchangeList(
    pathname === MAIN_PATHS.ShopPageSwap
  )

  const { user } = useAuthState()
  const { data: shops, isLoading: isLoadingShop } = useShopQuery(
    user?.id,
    pathname === MAIN_PATHS.ShopPage
  )

  useEffect(() => {
    setSelectedIndex(pathname === MAIN_PATHS.ShopPageSwap ? 1 : 0)
    if (pathname === MAIN_PATHS.ShopPageSwap) setShowLoading(isLoadingSwap)
    if (pathname === MAIN_PATHS.ShopPage) setShowLoading(isLoadingShop)
  }, [isLoadingSwap, pathname, isLoadingShop])

  const { showLoading: showFullScreenLoading, progress } = useLoading([showLoading])

  return showFullScreenLoading ? (
    <Wrapper>
      <Loading progress={progress} />
    </Wrapper>
  ) : (
    <ShopContextProvider
      value={{
        setTitle,
        setButtons,
        modalContent,
        setModalContent,
        closeModal,
        setSelectedItem,
        selectedItem,
        selectedHeartExchange,
        setSelectedHeartExchange,
        handleChangeTab,
        setModalType
      }}
    >
      {modalContent &&
        (modalType === 'window' ? (
          <ModalCommon
            theme="pink"
            title={title}
            open={Boolean(modalContent)}
            onClose={closeModal}
            buttons={buttons}
          >
            {modalContent}
          </ModalCommon>
        ) : (
          <Modal
            open={Boolean(modalContent)}
            onClose={closeModal}
            className="shop-modal first-line:sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-sm font-hiragino flex items-center justify-center p-2"
          >
            {modalContent}
          </Modal>
        ))}

      <MainLayout active="shop" classNameHeader="">
        <StandardPageWrapper className="pt-3 pb-[40px]">
          <ShopWrapper>
            <Tab.Group selectedIndex={selectedIndex}>
              <ColorHeader title="SHOP" tabList={tabList} onHandleTabChange={handleChangeTab} />
              <Tab.Panels>
                <Tab.Panel
                  className="pb-3 overflow-y-auto hidden-scroll"
                  style={{
                    height: 'calc(100dvh - 245px)'
                  }}
                >
                  <ShopDiamondTab setShowLoading={setShowLoading} data={shops} />
                </Tab.Panel>
                <Tab.Panel
                  className="pb-3 overflow-y-auto hidden-scroll mt-6"
                  style={{
                    height: 'calc(100dvh - 260px)'
                  }}
                >
                  <ShopHeartTab setShowLoading={setShowLoading} data={swapData} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </ShopWrapper>
        </StandardPageWrapper>
      </MainLayout>

      <TipsModal type="exchange" />
    </ShopContextProvider>
  )
}
ShopPage.propTypes = {
  tab: PropTypes.string.isRequired
}

const Wrapper = styled.div.attrs({
  className:
    'relative pt-12 pb-4 overflow-y-scroll hidden-scroll bg-white sm:w-desktop-width mx-auto'
})`
  height: calc(100dvh);
`

export default ShopPage
