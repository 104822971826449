import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { getScheduleToday } from 'src/services/get/getScheduleToday'
import { VOICE_LIVE_PATH } from 'src/routes/live'

import HeadPhone from 'src/assets/img/voicealbum/headphone_icon.png'
import ArrowRight from 'src/assets/img/voicealbum/btn_right.png'

import { SCHEDULE_CHARACTER_IMAGES } from 'src/constants/characters'
import ItemSchedule from 'src/components/myPage/livestream/ItemSchedule'

const Schedule = ({ sethaveScheduleToday }) => {
  const navigate = useNavigate()
  const [scheduleToday, setScheduleToday] = useState([])

  useEffect(() => {
    const scheduleToday = async () => {
      try {
        const response = await getScheduleToday()
        setScheduleToday(response || [])
        sethaveScheduleToday(response?.length > 0)
      } catch (error) {
        console.error('Error fetching lives:', error)
      }
    }
    scheduleToday()
  }, [])

  return (
    <div className="mt-8 mb-10 h-auto font-bold">
      {scheduleToday.length > 0 && (
        <div>
          <div className="flex justify-center items-center gap-2 whitespace-nowrap text-[14px] mb-1">
            <div className="flex items-center gap-1">
              <img src={HeadPhone} className="w-[14px] h-[12px]" />
              <span className="text-[#6F9BFF] text-[14px]">今日のLIVE配信スケジュール</span>
            </div>
            <div
              className="flex rounded-3xl justify-center gap-0.5 items-center bg-gradient-to-r-custom px-1 py-0.5 cursor-pointer"
              onClick={() =>
                navigate(VOICE_LIVE_PATH.LiveSchedulePage, { state: { tab: 'current_month' } })
              }
            >
              <span className="text-[11px] text-white">今月のスケジュール</span>
              <img src={ArrowRight} className="w-[7px] h-[10px]" />
            </div>
          </div>
          <div className="flex flex-col gap-2 bg-[#F3F3F3] items-center py-4 px-7">
            {scheduleToday.map((schedule, index) => {
              const character =
                SCHEDULE_CHARACTER_IMAGES[schedule.character_id] ||
                {
                  name: schedule.name,
                  color: 'bg-[linear-gradient(106.08deg,_#FF8484_7.18%,_#FF86C8_27.85%,_#D3B8FF_48.52%,_#3EF9C1_69.19%,_#FBD85C_89.85%)]'
                }
              return <ItemSchedule key={index} schedule={schedule} character={character} />
            })}
          </div>
        </div>
      )}
    </div>
  )
}

Schedule.propTypes = {
  sethaveScheduleToday: PropTypes.func.isRequired
}

export default Schedule
