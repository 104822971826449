import { useContext } from 'react'
import { HistoryRouteContext } from 'src/routes'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import NotificationIcon from 'src/components/common/NotificationIcon'
import newTabIcon from 'src/assets/img/menu/new_tab.png'

const LinkItem = ({ src, label, leftIconClassName, showNotice }) => (
  <div className="relative flex items-center gap-2 w-full">
    <div className="w-6 flex">
      <img src={src} alt={label} className={leftIconClassName} />
    </div>
    <p className="text-[0.8125rem] font-medium truncate max-w-[85%]">{label}</p>
    {showNotice && <NotificationIcon className='!relative -top-2'/>}
  </div>
)

const MenuItem = ({
  handleRedirect,
  className,
  leftIconClassName,
  label,
  src,
  to,
  from,
  isExternal = false,
  showNotice = false,
  isOpeningStory = false
}) => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const handleClickLink = (to) => {
    handleRedirect ? handleRedirect(to) : redirectTo(to)
  }

  return (
    <div
      className={clsx(
        'border-b border-menu-gray cursor-pointer h-11 flex font-MPlus1 w-[90%]',
        className
      )}
      onClick={() => handleClickLink(to)}
    >
      {isExternal ? (
        <div className="flex items-center gap-1 pr-1 w-full" target="_blank" rel="noreferrer">
          <LinkItem src={src} label={label} leftIconClassName={leftIconClassName} />
          <img src={newTabIcon} alt="new_tab" />
        </div>
      ) : isOpeningStory ? (
        <div className="relative flex items-center gap-1.5 w-full">
          <LinkItem src={src} label={label} leftIconClassName={leftIconClassName} />
        </div>
      ) : (
        <div className="relative flex items-center gap-1 w-full">
          <LinkItem
            src={src}
            label={label}
            leftIconClassName={leftIconClassName}
            showNotice={showNotice}
          />
        </div>
      )}
    </div>
  )
}

MenuItem.propTypes = {
  className: PropTypes.string,
  leftIconClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  isExternal: PropTypes.bool,
  showNotice: PropTypes.bool,
  isOpeningStory: PropTypes.bool,
  handleRedirect: PropTypes.func
}

LinkItem.propTypes = {
  src: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  leftIconClassName: PropTypes.string,
  showNotice: PropTypes.bool
}

LinkItem.defaultProps = {
  leftIconClassName: '',
  showNotice: false
}

export default MenuItem
