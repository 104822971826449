import { useContext, useEffect, useState } from 'react'
import useNewsListChannel from 'src/services/common/useNewsListChannel'

import PropTypes from 'prop-types'
import clsx from 'clsx'

import { genDateString } from 'src/lib/date'
import readNewsByPage from 'src/services/get/readNewsByPage'
import getNewsPerPage from 'src/services/get/getNewsPerPage'
import { NoticePageContext } from 'src/pages/myPage/Notice/NoticeLayout'

import NoContent from 'src/components/layouts/NoContent'
import Pagination from 'src/components/common/Pagination'
import TipsModal from 'src/components/common/TipsModal'

const NewsListPage = ({
  newsList,
  setNewsList,
  user,
  totalPage,
  setTotalPage,
  currPage,
  setCurrPage,
  handleChangeNews,
  checkHasUnreadNews
}) => {
  const newsMessage = useNewsListChannel()
  const { setLoading } = useContext(NoticePageContext)

  const [clickReadAllBtn, setClickReadAllBtn] = useState(false)
  const [hasUnreadNews, setHasUnreadNews] = useState(false)

  const getNewsPerPageWrapper = async () => {
    if (!user) return
    if (!clickReadAllBtn) setLoading(true)
    else setClickReadAllBtn(false)
    const res = await getNewsPerPage(user.id, currPage)
    setNewsList(res.items)
    setHasUnreadNews(res.items.some((news) => news.unreadUserIds?.some((id) => id === user.id)))
    setTotalPage(res.total_pages)
    setLoading(false)
  }

  const handleReadAll = async () => {
    setClickReadAllBtn(true)
    await readNewsByPage(currPage)
    checkHasUnreadNews()
    Promise.all([getNewsPerPageWrapper(), checkHasUnreadNews()])
  }

  useEffect(() => {
    getNewsPerPageWrapper()
  }, [user, currPage, newsMessage])

  return (
    <>
      {!newsList || newsList?.length === 0 ? (
        <div className="h-full flex items-center">
          <NoContent message="お知らせがありません" />
        </div>
      ) : (
        <>
          <div className="w-11/12 mx-auto">
            <div
              style={{ height: 'calc(100dvh - 340px)' }}
              className="overflow-y-auto hidden-scroll"
            >
              {newsList?.map((news, index) => (
                <div
                  key={news.id}
                  onClick={() => handleChangeNews(index, news.id)}
                  className={clsx(
                    'relative p-4 text-left text-sm font-hiragino cursor-pointer border-b border-sky-blue'
                  )}
                >
                  <div className="pr-2">
                    <div className="flex w-full justify-between items-center">
                      <span className="block text-xs font-[0.625rem] text-sky-blue">
                        {genDateString(news.startDisplayDate)}
                      </span>
                      {news.unreadUserIds?.some((d) => d === user.id) && (
                        <div className="bg-vibrant-orange w-2 h-2 rounded-full" />
                      )}
                    </div>
                    <span className="block text-normal pt-1 text-cornflower-blue font-semibold">
                      {news.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <Pagination totalPage={totalPage} currPage={currPage} setCurrPage={setCurrPage} />
            <button
              className={clsx(
                'w-5/6 rounded-2xl py-2 mt-4 font-semibold text-white',
                hasUnreadNews ? 'bg-sky-blue' : 'bg-button-gray'
              )}
              onClick={handleReadAll}
              disabled={!hasUnreadNews}
            >
              ページ内一括既読
            </button>
          </div>
        </>
      )}
      <TipsModal type="news" />
    </>
  )
}

NewsListPage.propTypes = {
  newsList: PropTypes.array,
  setNewsList: PropTypes.func,
  user: PropTypes.object,
  totalPage: PropTypes.number,
  setTotalPage: PropTypes.func,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  handleChangeNews: PropTypes.func,
  checkHasUnreadNews: PropTypes.func
}

export default NewsListPage
