import { useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import heartIcon from 'src/assets/img/mypage/recommend/heart_icon.png'
import heartFillIcon from 'src/assets/img/mypage/recommend/heart_fill_icon.png'
import { CharacterModal } from 'src/components/myPage/recommend/CharacterModal'
import { ConfirmModal } from 'src/components/myPage/recommend/ConfirmModal'

const DISABLED = 'disable'

export default function TalentItem({ character, handleUpdateRecommendMan }) {
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const bookmarkIcon = useMemo(
    () => (character.recommend ? heartFillIcon : heartIcon),
    [character.recommend]
  )

  const setOpenConfirmModal = () => {
    setIsOpenDetailModal(false)
    setIsOpenConfirmModal(true)
  }
  return (
    <>
      <div className="basis-1/3-gap-4">
        <div className="relative cursor-pointer rounded-2xl">
          <button onClick={() => setIsOpenDetailModal(true)} className='h-20'>
            <div className="w-full h-full rounded-2xl overflow-hidden absolute -top-1 left-0 z-[2]">
              <img
                src={character.icon}
                alt={character.name}
              />
            </div>
          </button>

          {character?.loveable_status !== DISABLED && (
            <img
              className="h-6 absolute bottom-2 right-1 z-10"
              src={bookmarkIcon}
              alt="o"
              onClick={() => setIsOpenConfirmModal(true)}
            />
          )}
        </div>
        <div className="font-medium text-xs text-[#9AC8FF] mt-1">{character.name}</div>
      </div>

      {isOpenDetailModal && (
        <CharacterModal
          isOpen={isOpenDetailModal}
          setIsOpen={setIsOpenDetailModal}
          character={character}
          setOpenConfirmModal={setOpenConfirmModal}
        />
      )}

      {isOpenConfirmModal && (
        <ConfirmModal
          character={character}
          isOpen={isOpenConfirmModal}
          setIsOpen={setIsOpenConfirmModal}
          handleUpdate={handleUpdateRecommendMan}
        />
      )}
    </>
  )
}

TalentItem.propTypes = {
  character: PropTypes.object,
  handleUpdateRecommendMan: PropTypes.func
}
