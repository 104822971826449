import { useState } from 'react'
import { Disclosure } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getFaqs } from 'src/services/get/getFaqs'
import HTMLReactParser from 'html-react-parser'
import { removeNewlineCode } from 'src/lib/string'
import useLoading from 'src/components/layouts/hooks/useLoading'

import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import TipsModal from 'src/components/common/TipsModal'
import Loading from 'src/components/layouts/Loading'
import { Wrapper } from 'src/components/common/Wrapper'

export const HelpPage = () => {
  const { data: groups, isLoading } = useQuery('groups', () => getFaqs())
  const { showLoading, progress } = useLoading([isLoading])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      <MainLayout active="menu" showHeader={true} classNameHeader="">
        <MenuWrapper overflow={true} className="flex flex-col items-center">
          <SubmenuHeader title={'ヘルプ・よくある質問'} />
          <div className="flex flex-col items-center gap-2 mt-4 px-3 overflow-y-auto hidden-scroll w-full">
            {groups
              ?.filter((group) => group.faqs.length > 0)
              ?.map((group) => (
                <>
                  <GroupAccordion key={group.id} name={group.name} faqs={group.faqs} />
                </>
              ))}
          </div>
          <TipsModal type="help" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

const HelpAccordion = ({ question, answer, hidden }) => {
  const modifiedQuestion = HTMLReactParser(removeNewlineCode(question))
  const finalQuestion =
    modifiedQuestion.charAt(0) === '▼' ? modifiedQuestion.slice(1) : modifiedQuestion
  return (
    <div
      className={`px-3 bg-menu-off-blue rounded-md w-full text-left border-4 border-[#FFE8F5] text-candy-pink font-Inter ${
        hidden && 'hidden'
      }`}
    >
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`w-full text-left gap-2 flex items-center py-2 font-semibold ${
                open && 'border-b border-[#FFE8F5]'
              }`}
            >
              <span className={`${!open && 'transform rotate-180'}`}>▲</span>
              <span className="text-[0.875rem]">
                {finalQuestion}
              </span>
            </Disclosure.Button>

            <Disclosure.Panel className={'flex gap-1.5 text-[0.875rem]'}>
              <span className="py-2 block text-silver font-black">A</span>
              <p className="py-2 block text-silver font-medium">
                {HTMLReactParser(removeNewlineCode(answer))}
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

const GroupAccordion = ({ name, faqs }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div
        className="px-3 py-2 text-cool-gray font-semibold rounded-md w-full text-left text-base border-[0.1875rem] border-cool-gray font-Inter"
        onClick={() => setOpen(!open)}
      >
        <div className={'w-full text-left flex gap-2 items-center'}>
          <span className={`${!open && 'transform rotate-180'}`}>▲</span>
          {HTMLReactParser(removeNewlineCode(name))}
        </div>
      </div>
      <div className={`w-full gap-2 flex flex-col ${open && 'mb-6'}`}>
        {faqs.map((faq) => (
          <HelpAccordion key={faq.id} question={faq.question} answer={faq.answer} hidden={!open} />
        ))}
      </div>
    </>
  )
}

HelpAccordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired
}

GroupAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired
}
