import { useContext } from 'react'
import PropTypes from 'prop-types'

import { MAIN_PATHS } from 'src/routes/main'
import ModalCommon from 'src/components/common/ModalCommon'
import { HistoryRouteContext } from 'src/routes'

const ModalPayShop = ({ show, handleClose }) => {
  const { redirectTo: redirect } = useContext(HistoryRouteContext)

  return (
    <ModalCommon
      open={show}
      onClose={handleClose}
      title="お知らせ"
      buttons={[
        {
          label: 'ショップ',
          onClick: () => redirect(MAIN_PATHS.ShopPage)
        }
      ]}
    >
      <div
        className={
          'bg-[#FFFFFF] px-2 pt-6 pb-12 text-center mb-4 relative flex flex-col overflow-auto'
        }
      >
        <p className="text-center">
          リアルタイムトークで彼のトークを表示するには、
          <p>
            <span className="text-[#E2A0E7]"> 「プラチナパスポート」</span>{' '}
            <span>が必要です。</span>
          </p>
          <p>ショップより、『プラチナパスポート』をご購入 </p>
          <p>の上、再度お越し下さい。</p>
        </p>
        <p className="mt-5 text-center">
          『プラチナパスポート』は1回購入するだけで、
          <p>
            永久に、<span className="text-[#503D8A]">リアルタイムトークが楽しめる・ </span>
          </p>
          <p className="text-[#503D8A]">
            無料ハートの回復量が増加・D×6全員の生い 立ち漫画を最後まで読める・LIVE配信に参加 可能、
          </p>
          <p> などの特典が多数！ </p>
        </p>
      </div>
    </ModalCommon>
  )
}

ModalPayShop.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default ModalPayShop
