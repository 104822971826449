import { useContext, useEffect } from 'react'
import { useQuery } from 'react-query'
import { getUserMiniGame } from 'src/services/hooks/useUserMiniGames'
import { useAuthState } from 'src/providers/AuthProviders'

import styled from 'styled-components'

import { HistoryRouteContext } from 'src/routes'
import { MAIN_PATHS } from 'src/routes/main'

import BtnReturn from 'src/assets/img/common/float_back_btn.png'
import Title from 'src/assets/img/mini_game/title/title_how_to_play.png'
import BgScoreBoard from 'src/assets/img/mini_game/bg_score_board.png'
import Mascot from 'src/assets/img/character/mascot/bye_bye.png'

const MiniGame = () => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const { user } = useAuthState()
  const { data, refetch } = useQuery(['userMiniGameData', user.id], () => getUserMiniGame(user.id))
  const play = () => {
    redirectTo(MAIN_PATHS.MiniGamePlay)
  }

  const handleClickBack = () => {
    redirectTo(-1)
  }

  useEffect(() => {
    refetch()
  }, [])
  return (
    <Wrapper>
      <button onClick={handleClickBack} className="absolute w-[4.75rem] h-[3.875rem] left-0 top-2">
        <img src={BtnReturn} className="w-[4.75rem]" alt="return button" />
      </button>
      <Container className="pt-[15%] flex justify-center items-center flex-col gap-6">
        <div className="bg-white w-[90%] rounded-md py-4">
          <img src={Title} alt="title" className="w-full" />
          <div>
            <div className="flex w-full px-2 items-center pl-[10%] justify-between">
              <p className="text-[0.8125rem] font-semibold text-[#484848] text-left">
                的に矢を投げるコケッ！
                <br />
                的に矢が刺さると得点GET！
                <br />
                500点獲得で{data?.heart}ハートGET!
                <br />
                （1日最大3回まで）
              </p>
              <img src={Mascot} alt="mascot" className="w-1/3" />
            </div>
            <div className="flex justify-center w-full mt-5">
              <div className="bg-[#FF86C8] w-[90%] rounded-md p-2">
                <p className="font-Inter font-semibold text-white text-[0.8125rem]">
                  ★赤に当たると高得点
                  <br />
                  ★黒に当たったり、同じ矢に当たると
                  <br />
                  GAMEOVER
                </p>
              </div>
            </div>
            <p className="text-[#FF86C8] text-xs font-Inter font-semibold mt-4">
              ※彼からトークのお返事が届くまでチャレンジ可能！
            </p>
          </div>
        </div>
        <div className="w-4/5 border-[3px] border-[#6F9BFF] rounded-xl font-semibold overflow-hidden">
          <div className="w-full flex justify-center border-b border-[#6F9BFF]">
            <span className="w-1/2 border-r border-[#6F9BFF] bg-[#FF86C8] text-white p-4">
              ハートボーナス
            </span>
            <span className="w-1/2 text-[#FF86C8] bg-white p-4 text-[20px]">
              残り{data?.remaining_number}回
            </span>
          </div>
          <div className="w-full flex justify-center">
            <span className="w-1/2 border-r border-[#6F9BFF] bg-[#FF86C8] text-white p-4">
              最高スコア
            </span>
            <span className="w-1/2 text-[#FF86C8] bg-white p-4 text-[20px]">{data?.best_score}点</span>
          </div>
        </div>
        <SelectBtnGroup>
          <button
            onClick={() => handleClickBack()}
            alt="back-btn"
            className="w-[45%] bg-[#C0C0C0] text-white text-[24px] font-bold text-center rounded-[32px] py-2 cursor-pointer"
          >
            キャンセル
          </button>
          <button
            onClick={play}
            alt="back-btn"
            className="w-[45%] bg-[#FF86C8] text-white text-[24px] font-bold text-center rounded-[32px] py-2 cursor-pointer"
          >
            スタート
          </button>
        </SelectBtnGroup>
      </Container>
    </Wrapper>
  )
}

export default MiniGame

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-[100dvw] h-[100dvh] mx-auto bg-no-repeat bg-center bg-cover text-[#333333] relative'
})`
  background-image: url(${BgScoreBoard});
`

const Container = styled.div.attrs({
  className: 'w-full mx-auto'
})``

const SelectBtnGroup = styled.div.attrs({
  className: 'flex justify-around w-full px-6'
})``
