import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useAuthState } from 'src/providers/AuthProviders'
import ModalPassport from 'src/components/common/ModalPassport'
import ModalUseCompass from 'src/components/common/ModalUseCompass'
import ModalNoPassport from 'src/components/common/ModalNoPassport'
import ModalNoCompass from 'src/components/common/ModalNoCompass'

import dreamCompassImg from 'src/assets/img/header-heart/dream_compass.png'
import dreamCompassLockImg from 'src/assets/img/header-heart/dream_compass_lock.png'
import platinumPassportImg from 'src/assets/img/header-heart/platinum_passport.png'
import platinumPassportLockImg from 'src/assets/img/header-heart/platinum_passport_lock.png'

const LayoutHeader = ({ rightContent, botContent, isPremiumTime, isExtraTime }) => {
  const { user } = useAuthState()
  const { dream_compass: dreamCompass, platinum_passport: platinumPassport } = user
  const [passportModalOpen, setPassportModalOpen] = useState(false)
  const [noPassportModalOpen, setNoPassportModalOpen] = useState(false)
  const [compassModalOpen, setCompassModalOpen] = useState(false)
  const [noCompassModalOpen, setNoCompassModalOpen] = useState(false)

  const tagTimeClass = () => {
    if (isPremiumTime) {
      return 'premium-time-bg'
    } else {
      return isExtraTime ? 'bg-[#FF4747]' : 'bg-[#6F9BFF]'
    }
  }

  const textTime = () => {
    if (isPremiumTime) {
      return 'PREMIUM TIME'
    } else {
      return isExtraTime ? 'EXTRA TIME' : 'NORMAL TIME'
    }
  }

  return (
    <div className="fixed top-0 w-full md:w-[400px] z-[2]">
      <div className="flex items-start justify-between w-full">
        <div className="flex ml-4 h-[35px]">
          <div className="rounded-sm bg-white transform skew-x-[-10deg] shadow-custom z-10">
            <div className="h-full flex flex-row items-center gap-1 pl-2 pr-3 skew-x-[10deg]">
              <img
                src={platinumPassport ? platinumPassportImg : platinumPassportLockImg}
                alt="platinum_passport"
                className="inline h-8 cursor-pointer"
                onClick={() =>
                  platinumPassport ? setPassportModalOpen(true) : setNoPassportModalOpen(true)
                }
              />
              <img
                src={dreamCompass ? dreamCompassImg : dreamCompassLockImg}
                alt="dream_compass"
                className="inline h-8 cursor-pointer"
                onClick={() =>
                  dreamCompass ? setCompassModalOpen(true) : setNoCompassModalOpen(true)
                }
              />
            </div>
          </div>
          <div
            className={`leading-5 pt-1 pb-2 px-5 -ml-1 mb-1 rounded-br-2xl border shadow-[0_0_5px_#fff] ${tagTimeClass()}`}
          >
            <span className="text-white text-[18px] tracking-wider font-extrabold whitespace-nowrap drop-shadow-[0_0_1px_#fff]">
              {textTime()}
            </span>
          </div>
        </div>
        {rightContent}
      </div>
      {botContent}
      <ModalPassport isOpen={passportModalOpen} onClose={() => setPassportModalOpen(false)} />
      <ModalNoPassport isOpen={noPassportModalOpen} onClose={() => setNoPassportModalOpen(false)} />
      <ModalUseCompass isOpen={compassModalOpen} onClose={() => setCompassModalOpen(false)} />
      <ModalNoCompass isOpen={noCompassModalOpen} onClose={() => setNoCompassModalOpen(false)} />
    </div>
  )
}

LayoutHeader.propTypes = {
  rightContent: PropTypes.node,
  botContent: PropTypes.node,
  isPremiumTime: PropTypes.bool,
  isExtraTime: PropTypes.bool
}
export { LayoutHeader }
