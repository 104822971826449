import React from 'react'
import { Wrapper } from 'src/components/common/Wrapper'
import PropTypes from 'prop-types'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import ToggleSwitch from 'src/components/common/ToggleSwitch'
import { CHARACTER_LINE_LINKS, CIRCLE_IMAGE_OF_CHARACTER } from 'src/constants/characters'
import NotificationSetting from 'src/models/NotificationSetting'
import IconManagerNew from 'src/assets/img/menu/icon_manager_new.png'
import SubmenuHeader from 'src/components/menu/SubmenuHeader'
import useLoading from 'src/components/layouts/hooks/useLoading'

const AccountPage = ({
  toggleAccept,
  isLoading,
  characterNotifications,
  globalSetting,
  toggleGlobalSetting
}) => {
  const { showLoading, progress } = useLoading([isLoading])

  if (showLoading) {
    return (
      <Wrapper>
        <Loading progress={progress} />
      </Wrapper>
    )
  }

  return (
    <>
      <MainLayout active="menu" classNameHeader={''}>
        <MenuWrapper className="flex flex-col" overflow={true}>
          <>
            <SubmenuHeader title={'通知設定'} />{' '}
            <div className="hidden-scroll overflow-y-auto h-[calc(100dvh-210px)] mt-2  flex flex-col relative gap-3 pt-3">
              <p className="absolute right-4 font-Inter font-semibold text-[0.625rem] text-candy-pink top-0">
                通知ON/OFF
              </p>
              <NotifcationSettingBar
                name="お知らせ"
                img={IconManagerNew}
                isLineFollow
                accept={globalSetting?.isNotification}
                setAccept={() => toggleGlobalSetting(!globalSetting?.isNotification)}
              />
              {characterNotifications?.map((character) => {
                if (character.navigator || character.id === 7) return false
                return (
                  <NotifcationSettingBar
                    name={character.name}
                    img={CIRCLE_IMAGE_OF_CHARACTER[character.id]}
                    isLineFollow={character.isLineFollow}
                    lineUrl={CHARACTER_LINE_LINKS[character.id]}
                    accept={character.isNotification}
                    setAccept={() => {
                      toggleAccept({
                        id: character.id,
                        isNotification: !character.isNotification
                      })
                    }}
                    key={character.id}
                  />
                )
              })}
            </div>
          </>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

AccountPage.propTypes = {
  toggleAccept: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  characterNotifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationSetting)),
  toggleGlobalSetting: PropTypes.func,
  globalSetting: PropTypes.object
}

export default AccountPage

const NotifcationSettingBar = ({ name, img, lineUrl, isLineFollow, accept, setAccept }) => {
  return (
    <div className="flex items-center justify-between w-full h-fit px-4 text-xs bg-white rounded font-Inter whitespace-nowrap cursor-pointer">
      <img className="w-12 h-12 rounded-full" src={img} alt={name} />
      <span className="w-16 text-[15px] text-light-pink font-bold">{name}</span>
      {isLineFollow ? (
        <span className="w-36 bg-[#d7d7d7] p-1 drop-shadow-md rounded-md text-white text-ellipsis">
          LINE友だち追加済
        </span>
      ) : (
        <a
          href={lineUrl}
          target="_blank"
          rel="noreferrer"
          className="w-36 bg-candy-pink p-1 drop-shadow-md rounded-md text-white text-ellipsis"
        >
          LINE友だち追加
        </a>
      )}
      <ToggleSwitch checked={accept} onChange={setAccept} srLabel={name} />
    </div>
  )
}

NotifcationSettingBar.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.any.isRequired,
  setAccept: PropTypes.func.isRequired,
  lineUrl: PropTypes.string.isRequired,
  isLineFollow: PropTypes.bool,
  accept: PropTypes.bool
}
