import { useState, useCallback, useContext } from 'react'
import { MAIN_PATHS } from 'src/routes/main'

import { MENU_PATHS } from 'src/routes/menu'
import styled, { keyframes } from 'styled-components'
import { HistoryRouteContext } from 'src/routes'

import { postSkipOpeningStory } from 'src/services/post/postSkipOpeningStory'

import StartText from 'src/assets/img/opening/startText.png'
import TapToStart from 'src/assets/img/opening/start-btn.png'
import LiveBtn from 'src/assets/img/opening/live-btn.png'
import BgOpening from 'src/assets/img/opening/bg-opening.png'
import Phone from 'src/assets/img/opening/phone.png'
import ModalCommon from 'src/components/common/ModalCommon'

export const OpeningPage = () => {
  const [isSkip, setIsSkip] = useState(false)
  const { redirectTo } = useContext(HistoryRouteContext)

  const redirect = useCallback((path) => redirectTo(path), [])

  const handleStartOpeningStory = () => {
    redirect(MENU_PATHS.OpeningStoryDetail.replace(':id', 'first-story'))
  }

  return (
    <Wrapper>
      <img src={Phone} className="absolute left-1/2 top-[14dvh]" />
      <div
        className="absolute cursor-pointer text-[11px] bottom-[10%] left-1/2 font-bold z-10 transform -translate-x-1/2 w-full"
        onClick={() => setIsSkip(true)}
      >
        <img src={LiveBtn} className="w-1/2 mx-auto" alt="オープニングストーリー一括スキップ" />
      </div>
      <div />
      <AnimatedContainer backgroundImage={TapToStart} onClick={() => handleStartOpeningStory()}>
        <AnimatedImage src={StartText} alt="Start Text" />
      </AnimatedContainer>
      {isSkip && (
        <ModalCommon
          open={isSkip}
          onClose={() => setIsSkip(false)}
          title={'ストーリースキップ'}
          buttons={[
            {
              label: 'キャンセル',
              onClick: () => setIsSkip(false),
              type: 'close'
            },
            {
              label: 'スキップ',
              onClick: () => {
                postSkipOpeningStory()
                localStorage.setItem('isStoryLoading', true)
                redirect(MAIN_PATHS.MyPage)
              }
            }
          ]}
        >
          <div className="text-cornflower-blue font-semibold font-Inter text-xs bg-white p-6">
            <h6 className="mb-3.5 text-[1.25rem] font-semibold text-center leading-6">
              オープニングストーリーを
              <br />
              一括スキップしますか?
            </h6>
            <div className="mt-2">
              <p className=" flex items-center text-left font-semibold whitespace-pre-line">
                {`オープニングストーリーを最後まで読むと、
                チャット送信に必要なアイテムがもらえま
                す。
                ※オープニングストーリーは後から読み返す
                こともできますが、アイテムは今回限りとな
                ります。
                ※所要時間約10分`}
              </p>
            </div>
          </div>
        </ModalCommon>
      )}
    </Wrapper>
  )
}

const fadeInFromBottom = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-[100dvh] mx-auto relative bg-no-repeat bg-left bg-cover'
})`
  background-image: url(${BgOpening});
  animation: ${fadeInFromBottom} 1.5s ease-out;
  animation-fill-mode: forwards;
`

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`

const AnimatedContainer = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-image: url(${(props) => props.backgroundImage});
  background-size: 120%;
  background-position: center;
  width: 100%;
  height: 80px;
  animation: ${fadeInOut} 3s infinite ease-in-out;
`

const AnimatedImage = styled.img`
  position: absolute;
  top: 62%;
  left: 50%;
  transform: translate(-50%, -50%);
`
