import React, { useState } from 'react'
import { LayoutBackground } from '../../components/LayoutBackground'
import { LayoutHeader } from '../../components/LayoutHeader'
import { useLiveStreamInfos } from '../../hooks/useLiveStreamInfos'
import { useParams } from 'react-router-dom'
import _ from 'lodash'
import { DuringProvider } from './_Provider'
import LiveRedDiamond from './_LiveRedDiamond'
import useLiveChannel from '../../../../services/common/useLiveChannel'

// eslint-disable-next-line react/display-name
const withDuringLayout = (Component, options) => () => {
  const { channel_id: channelId = '', title } = useParams()
  const liveInfoQuery = useLiveStreamInfos(title, channelId)
  const [animations, setAnimations] = useState([])
  const [svgCounter, setSvgCounter] = useState(0)

  const liveItemsSocket = useLiveChannel({
    conversationId: liveInfoQuery.data?.live_stream?.id,
    enableExtraTimeQuery: liveInfoQuery.data?.enable_extra_time
  })

  return (
    <>
      {_.get(liveInfoQuery, 'data') && (
        <DuringProvider
          value={{
            title,
            channelId,
            liveInfoQuery,
            setAnimationItems: (data) => setAnimations(data),
            setImageCounter: (counter) => setSvgCounter(counter),
            liveItemsSocket
          }}
        >
          {animations.length > 0 && (
            <div
              id="animation-block"
              className="fixed bg-[#0000008c] top-0 left-[50%] translate-x-[-50%] h-screen w-screen z-[5] md:w-[400px]"
            >
              {animations.map((animation, index) =>
                animation.animation_url.endsWith('.mp4') ? (
                  <video
                    key={index}
                    src={`${animation.animation_url}?counter=${svgCounter}`}
                    className="fixed"
                    style={{ bottom: '100px', left: '0px' }}
                    onEnded={() => {
                      liveItemsSocket.setExpensiveItem({})
                    }}
                    autoPlay
                    muted
                    playsInline
                  />
                ) : (
                  <img
                    key={index}
                    src={`${animation.animation_url}?counter=${svgCounter}`}
                    className="fixed"
                    style={{ bottom: '100px', left: '0px', width: '100%' }}
                  />
                )
              )}
            </div>
          )}

          <div className="w-screen h-screen mx-auto md:w-desktop-width m-h-screen">
            <LayoutHeader
              rightContent={
                <span className="mt-2 mr-[12px]">
                  <LiveRedDiamond />
                </span>
              }
              isPremiumTime={_.get(liveInfoQuery, 'data.is_premium_time')}
              isExtraTime={_.get(liveInfoQuery, 'data.is_extra_time')}
            />
            <main>
              <LayoutBackground
                bg={_.get(liveInfoQuery, 'data.live_stream.livestream_image.url')}
                isNoFooter
              />
              {/* <LineHeader /> */}
              <div className="pt-[35px] w-full md:w-[400px] h-screen">
                <Component />
              </div>
            </main>
          </div>
        </DuringProvider>
      )}
    </>
  )
}

export { withDuringLayout }
