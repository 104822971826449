// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useCallback, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { useAuthState } from 'src/providers/AuthProviders'
import { useLocation, useParams } from 'react-router-dom'
import { useConversation } from 'src/pages/main/dmTalk/hooks/useConversation'
import { useQueryClient } from 'react-query'
import { Modal } from 'src/components/common/Modal'
import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'
import BackBtn from 'src/assets/img/mini_game/btn/btn_back.png'
import PlayBtn from 'src/assets/img/mini_game/btn/btn_play.png'
import MiniGameTitle from 'src/assets/img/mini_game/title/tittle_minigame.png'
import ModalBg from 'src/assets/img/mini_game/daishi.png'
import BackGroundDefault from 'src/assets/img/common/bg_common.jpg'
import BtnClose from 'src/assets/img/common/btn-close.png'
import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import FloatBackButton from 'src/assets/img/common/float_back_btn.png'
import IconExpand from 'src/assets/img/realtalk/expand_talk_new.svg'
import ArrowExpand from 'src/assets/img/realtalk/arrow_expand.png'
import ContainerIcon from 'src/assets/img/realtalk/container.png'
import RoundedIcon from 'src/assets/img/realtalk/rounded.svg'
import BgRealTalk from 'src/assets/img/realtalk/bg-realtalk.png'
import IconToFirstMess from 'src/assets/img/realtalk/first_mess.png'
import IconLoveCharacter from 'src/assets/img/realtalk/icon_love_character.png'
import { ItemChatClient } from './components/ItemChatClient'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { MYPAGE_CHARACTER_IMAGES } from 'src/constants/characters'
import TipsModal from 'src/components/common/TipsModal'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'
import { useLoadingMore } from 'src/services/hooks/useLoadingMore'
import { getConversationItem } from 'src/services/get/getChat'
import { InputStoryTalk } from 'src/components/message/ChatBox/InputStoryTalk'
import { ItemChat } from 'src/components/message/ChatBox/Item'
import { HistoryRouteContext } from 'src/routes'
import { SettingLvCharacter } from 'src/components/common/SettingLvCharacter'
import { useDispatch } from 'react-redux'
import { resetLayout, setDisplayFooter, setDisplayHeader } from 'src/redux/layout/setDisplayLayout'
import { MAIN_PATHS } from 'src/routes/main'
import LoadingIcon from 'src/components/layouts/LoadingIcon'
import NoContent from 'src/components/layouts/NoContent'
import { Header } from 'src/components/layouts/Header'
import { roomType } from 'src/constants/roomTypeList'

const StoryTalkDetail = () => {
  const { redirectTo } = useContext(HistoryRouteContext)
  const [sizeInput, setSizeInput] = useState(0)
  const { user } = useAuthState()
  const roomChatRef = useRef()
  const lastScrollHeight = useRef(0)
  const {
    data: conversationData,
    isFetching,
    fetchNextPage,
    hasNextPage,
    refetch,
    addNewMessageToCache,
    updateMessageToCache,
    isFetched
  } = useConversation()
  const { characterId } = useParams()
  const [character, setCharacter] = useState({})
  const [loveLvSettings, setLoveLvSettings] = useState({})
  const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
  const [multiLevel, setMultiLevel] = useState({})
  const [backgroundSetting, setBackgroundSetting] = useState({})
  const [present, setPresent] = useState(null)
  const [checkText, setCheckText] = useState(0)
  const [files, setFiles] = useState('')
  const [fileVideo, setFileVideo] = useState([])
  const [messages, setMessages] = useState([])
  const [scroll, setScroll] = useState(true)
  const [isOpenGift, setIsOpenGift] = useState(false)
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [conversationId, setConversationId] = useState()
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [previousTestId, setPreviousTestId] = useState(null)
  const [expandTalk, setExpandTalk] = useState(false)
  const [bgImage, setBgImage] = useState('')
  const [isOpenGameModal, setIsOpenGameModal] = useState(false)
  const [isBgImageSet, setIsBgImageSet] = useState(false)

  const isNoContent = useMemo(
    () => isFetched && !conversationData?.pages?.find((page) => page?.first_page)?.character?.allow_story_talk,
    [conversationData, isFetched]
  )

  const scrollToLast = () => {
    roomChatRef.current?.scrollTo({
      top: roomChatRef.current?.scrollHeight - lastScrollHeight.current
    })
    lastScrollHeight.current = 0
    if (document.querySelector('#start')) {
      setIsDuplicate(false)
    }
  }

  useEffect(() => {
    if (!conversationData) return

    setMessages(conversationData.pages.map((page) => page.messages).flat())
    const firstPage = conversationData.pages.find((page) => page.first_page)

    if (firstPage) {
      setCharacter(firstPage.character)
      if (!isBgImageSet) {
        setBgImage(firstPage.last_character_background_image_url)
        setIsBgImageSet(true)
      }
      setConversationId(firstPage.conversation_id)
      setLoveLvSettings(firstPage.character_setting_level)
    }
  }, [conversationData])

  useEffect(() => {
    if (conversationData) {
      refetch()
    }
  }, [])

  const changeBgImage = useCallback(
    (msg) => {
      if (!msg || !msg.is_heart_consumption || !msg.character_background_image_url) return
      setBgImage(msg.character_background_image_url)
    },
    [setBgImage]
  )

  useLoadingMore({
    selector: '#start',
    skip: !hasNextPage,
    onLoadMore: () => {
      if (isDuplicate) return
      setIsDuplicate(true)
      setTimeout(() => {
        fetchNextPage()
        lastScrollHeight.current = roomChatRef.current?.scrollHeight ?? 0
      })
    }
  })

  useEffect(() => {
    if (scroll) {
      scrollToLast()
    } else {
      setScroll(true)
    }
  }, [messages])

  useEffect(() => {
    if (!currentModal) return
    if (currentModal.id) {
      setIsGrowModalOpen(true)
    }
  }, [currentModal])

  const closeMultiLoveModal = useCallback(() => {
    setIsMultiLoveModalOpen(false)
    refetch()
  }, [setIsMultiLoveModalOpen, refetch])

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1]?.id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
      setIsOpenGift(true)
    }
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    } else {
      setIsOpenGift(true)
    }
  }

  const handleOpenGift = async (type, msg) => {
    setCheckText(type === 'character_cloth' ? 1 : 0)
    const giftId = type === 'character_cloth' ? msg.character_cloth_id : msg.setting_item_id
    const giftAmount = type === 'character_cloth' ? msg.number_of_avatar : msg.setting_item_amount
    if (
      (type === 'character_cloth' && !msg.is_received_cloth) ||
      (type === 'setting_item' && !msg.is_received_present)
    ) {
      const res = await getConversationItem(msg.character_chat_id, type, giftId, giftAmount)
      if (res) {
        if (res.characterLevel) {
          setCharacter({ ...character, level: res.characterLevel })
          setLoveLvSettings({ ...loveLvSettings, level: res.characterLevel })
        }
        if (!res.data.items) return
        setPresent(res.data.items)
        if (!res.characterLevels) return
        handleSetModalList(res.characterLevels)
        if (type === 'character_cloth') {
          msg.is_received_cloth = true
        } else if (type === 'setting_item') {
          msg.is_received_present = true
        }
      } else {
        console.log('プレゼントは受け取り済みです')
      }
    }
  }

  const handleUpdateMessage = (msg) => {
    const copyMessages = [...messages]
    const index = copyMessages.findIndex(
      (message) => msg.character_chat_id === message.character_chat_id
    )
    if (index >= 0) {
      copyMessages[index] = msg
      setMessages(copyMessages)
      updateMessageToCache(msg)
      changeBgImage(msg)
    }
  }

  useEffect(() => {
    setBackgroundSetting(MYPAGE_CHARACTER_IMAGES[characterId])
  }, [characterId])

  const isMessageValidForCache = (msg, idCurrent) => {
    return msg.description || msg.attachment_url || (msg.item && idCurrent !== previousTestId)
  }

  const handleNewMessages = (msg, checkStatus) => {
    if (!msg) return
    changeBgImage(msg)

    const idCurrent = msg?.item?.item_id
    setMessages((prevMessages) => [...prevMessages, msg])

    if (isMessageValidForCache(msg, idCurrent)) {
      addNewMessageToCache(msg)
    }

    if (checkStatus === 'messageItem') {
      setPreviousTestId(idCurrent)
    }
  }

  const dispatch = useDispatch()

  const handleClose = () => {
    setIsOpenGameModal(false)
  }

  return (
    <MainLayout showFooter={false} classNameHeader="">
      {isNoContent ? (
        <div className="h-full flex items-center">
          <NoContent
            isModal
            message={'直前のページに戻ります。\nナビゲーターにお知らせください'}
            redirectUrl={MAIN_PATHS.MessagePage}
          />
        </div>
      ) : (
        <>
          <MultiLoveEffectModal
            character={character}
            isMultiLoveModalOpen={isMultiLoveModalOpen}
            multiLevel={multiLevel}
            onClose={closeMultiLoveModal}
          />
          <Container
            chatBgImage={
              conversationData?.background_type === 1 ? backgroundSetting?.room : BackGroundDefault
            }
          >
            <div className="absolute z-[100] h-[100dvh] w-full">
              <Header className="" />
              <button
                className="absolute z-[100] left-[5px] top-[3.25rem]"
                onClick={() => {
                  dispatch(resetLayout())
                  redirectTo(MAIN_PATHS.MessagePage)
                }}
              >
                <img src={FloatBackButton} alt="" className='w-[4.75rem]'/>
              </button>

              <SettingLvCharacter
                loveLvSettings={loveLvSettings}
                character={character}
                isMultiLoveModalOpen={isMultiLoveModalOpen}
                multiLevel={multiLevel}
                onClose={closeMultiLoveModal}
              />
              <div
                className="w-10 h-10 flex items-center"
                style={{
                  backgroundImage: `url(${RoundedIcon})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain'
                }}
              >
                <img
                  className={`${
                    expandTalk ? '' : 'rotate-180'
                  } absolute w-4 top-[7px] left-[18px] transition-transform duration-500`}
                  src={ArrowExpand}
                  alt=""
                />
                <img
                  className={`${
                    expandTalk ? 'rotate-180' : ''
                  } absolute w-4 bottom-2 left-1.5 transition-transform duration-500`}
                  src={ArrowExpand}
                  alt=""
                />
                <img
                  className={`${expandTalk ? 'hidden' : 'block'} absolute top-1 left-1 !w-8`}
                  src={ContainerIcon}
                  alt=""
                />
                <button
                  className="absolute z-[100] top-[90px] right-2"
                  onClick={() => {
                    dispatch(setDisplayHeader())
                    dispatch(setDisplayFooter())
                    setExpandTalk((pre) => !pre)
                  }}
                >
                  <div
                    className="relative w-10 h-10 flex items-center"
                    style={{
                      backgroundImage: `url(${RoundedIcon})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain'
                    }}
                  >
                    <img
                      className={`${
                        expandTalk ? '' : 'rotate-180'
                      } absolute w-4 top-[0.4375rem] left-[1.125rem] transition-transform duration-500`}
                      src={ArrowExpand}
                      alt=""
                    />
                    <img
                      className={`${
                        expandTalk ? 'rotate-180' : ''
                      } absolute w-4 bottom-2 left-1.5 transition-transform duration-500`}
                      src={ArrowExpand}
                      alt=""
                    />
                    <img
                      className={`${
                        expandTalk ? 'hidden' : 'block'
                      } absolute w-[2.125rem] left-[3px]`}
                      src={ContainerIcon}
                      alt=""
                    />
                  </div>
                </button>
                <WrapperChatBox isExpand={expandTalk} sizeInput={sizeInput}>
                  <div className="fixed z-10 top-0 img-bg-realtalk">
                    <img
                      src={bgImage || BackGroundDefault}
                      alt=""
                      className="w-full h-screen animation-expand object-cover"
                    />
                  </div>
                </WrapperChatBox>
                <div className="fixed bottom-0 mx-auto z-10 w-[100dvw] sm:w-desktop-width">
                  <div
                    ref={roomChatRef}
                    className={`${
                      !expandTalk ? 'h-[40dvh]' : 'h-[0px]'
                    } z-20 animation-expand w-full overflow-auto bg-white/80 hidden-scroll`}
                  >
                    {isFetching ? <LoadingIcon /> : <div id="start" />}
                    {messages.map((message, i) => (
                      <div key={i}>
                        {message?.is_owner ? (
                          <ItemChatClient data={message} />
                        ) : (
                          <ItemChat
                            msg={message}
                            loveLvSettings={loveLvSettings}
                            character={character}
                            isScrollOpenTalk={() => setScroll(false)}
                            handleUpdateMessage={handleUpdateMessage}
                            indexMes={i}
                            handleOpenGift={handleOpenGift}
                            changeBgImage={changeBgImage}
                          />
                        )}
                        <br />
                      </div>
                    ))}
                  </div>
                  <InputStoryTalk
                    setCheckClickIconBg={() => {}}
                    setIsClickIconBg={() => {}}
                    setCheckClickIconSetting={() => {}}
                    senderId={user?.id}
                    conversationId={conversationId}
                    handleNewMessages={(msg, checkStatus) => handleNewMessages(msg, checkStatus)}
                    setSizeInput={setSizeInput}
                    characterId={Number(characterId)}
                    loveLvSettings={loveLvSettings}
                    character={character}
                    setFiles={setFiles}
                    files={files}
                    setFileVideo={setFileVideo}
                    fileVideo={fileVideo}
                    setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                    setMultiLevel={setMultiLevel}
                  />
                </div>
              </div>
              <Modal
                open={isOpenGameModal}
                onClose={handleClose}
                className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-Inter flex items-center justify-center"
              >
                <ModalContainer>
                  <ModalHeader>
                    <img src={MiniGameTitle} alt="minigame_title" />
                  </ModalHeader>
                  <img
                    src={MascotChara}
                    alt="mascot_chara"
                    className="block w-[56px] h-[48px] absolute right-6 top-[30%]"
                  />
                  <ModalBody>
                    <p>彼からのお返事が届く間のみ、</p>
                    <p>ミニゲームを遊んでハートを</p>
                    <p>GETする事ができるョ！</p>
                    <p className="mb-5">お返事が届いた時点で強制終了だコケッ！</p>
                    <p>※ゲームは何回でも出来るけど</p>
                    <p>ハートが貰えるのは1日</p>
                    <p>(am5時～翌日am4時59分) の24時間の間で3回までだョ！</p>
                  </ModalBody>
                  <ModalFooter>
                    <img
                      src={BackBtn}
                      onClick={() => setIsOpenGameModal(false)}
                      alt="back_btn"
                      className="w-[116px] mr-4 cursor-pointer"
                    />
                    <img
                      src={PlayBtn}
                      onClick={() => redirectTo(MAIN_PATHS.MiniGame, { state: { characterId } })}
                      alt="play_btn"
                      className="w-[116px] cursor-pointer"
                    />
                  </ModalFooter>
                </ModalContainer>
              </Modal>
              {currentModal && currentModal.id && (
                <GrowEffectModal
                  character={currentModal}
                  isGrowModalOpen={isGrowModalOpen}
                  level={{
                    currentLevel: currentModal.current_level,
                    nextLevel: currentModal.next_level
                  }}
                  onClose={closeGrowModal}
                />
              )}
              <Modal
                open={isOpenGift}
                onClose={() => setIsOpenGift(false)}
                className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-Inter text-[#333333]"
              >
                <div
                  onClick={() => setIsOpenGift(false)}
                  className="w-[50px] h-[44px] absolute -top-2 -right-2 z-50 cursor-pointer"
                >
                  <img className="w-full h-full" src={BtnClose} alt="close" />
                </div>
                <GiftModalBody className="py-7">
                  <ModalTitle>プレゼント受け取り</ModalTitle>
                  <div className="h-[310px] overflow-y-scroll">
                    {present?.map((item, index) => (
                      <div key={index}>
                        <GiftBox>
                          {item?.gift_image && (
                            <div className="w-full h-full">
                              <img
                                className="aspect-square object-cover"
                                src={item.gift_image}
                                alt="gift"
                              />
                            </div>
                          )}
                        </GiftBox>
                        <h5 className="font-vldLineGR mt-[3px] text-xl leading-[25px] text-center mb-4">
                          {item?.gift_name}
                        </h5>
                      </div>
                    ))}
                    {checkText === 1 ? null : (
                      <div className="font-light text-sm text-center leading-[21px] mt-2 mb-2">
                        メニュー＞アイテムから確認できます。
                      </div>
                    )}
                  </div>
                </GiftModalBody>
              </Modal>

              {/* Tips modal */}
              <TipsModal type="realtalk" isDetail />
            </div>
          </Container>
        </>
      )}
    </MainLayout>
  )
}

const ModalContainer = styled.div.attrs({
  className: 'h-[400px] w-[320px] bg-fill bg-no-repeat'
})`
  background-image: url(${ModalBg});
`

const ModalHeader = styled.div.attrs({
  className: 'w-[85%] mx-auto mt-[44px]'
})``

const ModalBody = styled.div.attrs({
  className: 'mt-6 w-[72%] mx-auto text-center text-xs leading-4 font-light'
})``

const ModalFooter = styled.div.attrs({
  className: 'flex justify-center w-[85%] mx-auto mt-6'
})``

const Container = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative font-Inter overflow-hidden bg-no-repeat bg-left-top bg-cover'
})``

const WrapperChatBox = styled.div.attrs({
  className: 'overflow-y-auto overflow-x-hidden hidden-scroll'
})`
  height: 100dvh;
  padding-bottom: 64px;
  position: relative;
  padding-top: 282px;

  /* Expand or collapse logic if you need dynamic styles */
  ${({ isExpand }) =>
    isExpand
      ? `
         /* styles if expanded */
       `
      : `
         /* styles if not expanded */
       `}
`

const GiftModalBody = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 2rem;
  position: relative;
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div`
  height: fit-content;
  padding: 0.4375rem 2rem;
  margin: 0 1rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5625rem;
  color: #fff;
  font-family: 'Inter';
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;

  @media (max-width: 410px) {
    font-size: 1rem;
    padding: 0.75rem;
  }

  @media (max-width: 316px) {
    font-size: 0.75rem;
    padding: 0.75rem;
  }
`

const GiftBox = styled.div`
  margin: 1.5rem auto;
  width: 152px;
  height: 172px;
  background-size: 100% 100%;
`

export default StoryTalkDetail
