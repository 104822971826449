import { useState, useContext } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useDispatch } from 'react-redux'

import getUnreadNews from 'src/services/get/getUnreadNews'
import { setHasNews, setHasNewsNotifications } from 'src/redux/notfication/getNotifications'

import NoticeLayout from 'src/pages/myPage/Notice/NoticeLayout'
import NewsListPage from 'src/pages/myPage/Notice/News/NewsList'
import News from 'src/pages/myPage/Notice/News/NewsDetail'
import { useParams } from 'react-router-dom'
import { HistoryRouteContext } from 'src/routes'

import { MYPAGE_PATHS } from 'src/routes/myPage'

const NewsListContainer = () => {
  const { user } = useAuthState()
  const { redirectTo } = useContext(HistoryRouteContext)
  const dispatch = useDispatch()

  const [newsList, setNewsList] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [currPage, setCurrPage] = useState(1)
  const [currNewsIndex, setCurrNewsIndex] = useState(null)
  const { id } = useParams()

  const checkHasUnreadNews = async () => {
    const res = await getUnreadNews(user.id)
    dispatch(setHasNewsNotifications(res))
    dispatch(setHasNews(res))
  }

  const handleChangeNews = (index, id) => {
    dispatch(setHasNews(false))
    setCurrNewsIndex(index)
    if (id) {
      redirectTo(MYPAGE_PATHS.NewsDetailPage.replace(':id', id), { replace: true })
    } else {
      checkHasUnreadNews()
      redirectTo(MYPAGE_PATHS.NewsListPage, { replace: true })
    }
  }

  return (
    <>
      {currNewsIndex === null && !id ? (
        <NoticeLayout title="お知らせ" activetab="news" classNameHeader="!bg-gradient-sky-to-pink">
          <NewsListPage
            newsList={newsList}
            setNewsList={setNewsList}
            user={user}
            totalPage={totalPage}
            setTotalPage={setTotalPage}
            currPage={currPage}
            setCurrPage={setCurrPage}
            handleChangeNews={handleChangeNews}
            checkHasUnreadNews={checkHasUnreadNews}
          />
        </NoticeLayout>
      ) : (
        <NoticeLayout
          title="お知らせ"
          activetab="news"
          isHideHeader={true}
          height="h-[calc(100dvh - 120px)] my-0"
          classNameHeader=""
        >
          <News
            id={id}
            user={user}
            newsList={newsList}
            currPage={currPage}
            setCurrPage={setCurrPage}
            currNewsIndex={currNewsIndex}
            handleChangeNews={handleChangeNews}
          />
        </NoticeLayout>
      )}
    </>
  )
}

export default NewsListContainer
