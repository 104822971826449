import { useEffect, useState } from 'react'

const useProgress = (isLoading) => {
  const [progress, setProgress] = useState(0)
  const [showLoading, setShowLoading] = useState(false)

  useEffect(() => {
    let intervalId = null
    let timeoutId = null
    const isStoryLoading = localStorage.getItem('isStoryLoading')

    const clearTimers = () => {
      if (intervalId) clearInterval(intervalId)
      if (timeoutId) clearTimeout(timeoutId)
    }

    if (isLoading) {
      setShowLoading(true)
      const maxProgress = isStoryLoading ? 100 : 90
      const speed = isStoryLoading ? 5 : 30
      intervalId = setInterval(() => {
        setProgress((prev) => (prev < maxProgress ? prev + 1 : prev))
      }, speed)
    } else {
      setProgress(100)

      if (isStoryLoading) {
        localStorage.removeItem('isStoryLoading')
        setShowLoading(false)
      } else {
        timeoutId = setTimeout(() => {
          setShowLoading(false)
        }, 300)
      }
    }

    return clearTimers
  }, [isLoading])

  return { progress, showLoading }
}

export default useProgress
