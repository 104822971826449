import React from 'react'
import PropTypes from 'prop-types'
import ModalCommon from 'src/components/common/ModalCommon'
import UseCompass from 'src/assets/img/use-compass.png'
import ChickenIcon from 'src/assets/img/chicken-icon.png'
import ModalBg from 'src/assets/img/common/modal_bg.png'

const ModalUseCompass = ({ isOpen, onClose }) => {
  const modalButtons = [
    {
      label: '閉じる',
      onClick: () => {
        onClose()
      }
    }
  ]

  return (
    <ModalCommon open={isOpen} onClose={onClose} title="夢のコンパス" buttons={modalButtons}>
      <div className="bg-white py-2 px-1 text-center font-bold font-Inter">
        <div
          className="relative h-[150px] bg-no-repeat bg-cover bg-center rounded-xl mx-1"
          style={{
            backgroundImage: `url(${ModalBg})`
          }}
        >
          <img
            src={UseCompass}
            alt=""
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
          <img
            className="absolute right-6 bottom-0 rotate-12 w-[73px] h-[73px]"
            src={ChickenIcon}
            alt=""
          />
        </div>
        <p className="mt-2 mb-3 text-[15px]">
          このアイコンは、｢夢のコンパス有効期限内｣のアイコンだコケッ！
        </p>
        <p className="text-xs mb-2">夢のコンパスを所有していると…</p>
        <p className="text-[15px]">
          ・トーク送信が4ハート→3ハートに減少
          <br />
          ・毎日1回アイテムガチャ無料
          <br />
          ・アイテム使用時の上昇LOVE度が1.5倍！
        </p>
        <p className="text-xs mt-1">など、更にお得！夢のコンパスは30日間有効！</p>
        <p className="text-xs">
          ｢ショップ｣から｢<span className="text-[#D16EFF]">夢のコンパス</span>｣を購入する事で、
        </p>
        <p className="text-xs">鍵マークが解放されるコケッ！</p>
      </div>
    </ModalCommon>
  )
}

ModalUseCompass.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
}

export default ModalUseCompass
