/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { RecoverHeart } from 'src/components/common/RecoverHeart'
import ModalPassport from 'src/components/common/ModalPassport'
import ModalUseCompass from 'src/components/common/ModalUseCompass'
import ModalNoPassport from 'src/components/common/ModalNoPassport'
import ModalNoCompass from 'src/components/common/ModalNoCompass'
import dream_compass_img from 'src/assets/img/header-heart/dream_compass.png'
import dream_compass_lock_img from 'src/assets/img/header-heart/dream_compass_lock.png'
import platinum_passport_img from 'src/assets/img/header-heart/platinum_passport.png'
import platinum_passport_lock_img from 'src/assets/img/header-heart/platinum_passport_lock.png'

export const HeaderHeart = () => {
  const { user } = useAuthState()
  const {
    dream_compass: dreamCompass,
    platinum_passport: platinumPassport,
    possession_heart_recovery_or_maximum: heartRecoveryMaximum
  } = user

  const [passportModalOpen, setPassportModalOpen] = useState(false)
  const [noPassportModalOpen, setNoPassportModalOpen] = useState(false)
  const [compassModalOpen, setCompassModalOpen] = useState(false)
  const [noCompassModalOpen, setNoCompassModalOpen] = useState(false)

  return (
    <div className="shrink-0 drop-shadow-custom">
      <div
        className="flex items-center gap-1 bg-white pl-2 pr-3 py-[2px]"
        style={{
          clipPath: 'polygon(100% 0, 90% 100%, 0% 100%, 0% 0%)'
        }}
      >
        <RecoverHeart
          max={platinumPassport && dreamCompass ? 12 : platinumPassport && !dreamCompass ? 8 : 4}
          heartRecoveryMaximum={heartRecoveryMaximum}
        />
        <img
          src={platinumPassport ? platinum_passport_img : platinum_passport_lock_img}
          alt="platinum_passport"
          className="inline h-8 cursor-pointer"
          onClick={() =>
            platinumPassport ? setPassportModalOpen(true) : setNoPassportModalOpen(true)
          }
        />
        <img
          src={dreamCompass ? dream_compass_img : dream_compass_lock_img}
          alt="dream_compass"
          className="inline h-8 cursor-pointer"
          onClick={() => (dreamCompass ? setCompassModalOpen(true) : setNoCompassModalOpen(true))}
        />
      </div>
      <ModalPassport isOpen={passportModalOpen} onClose={() => setPassportModalOpen(false)} />
      <ModalNoPassport isOpen={noPassportModalOpen} onClose={() => setNoPassportModalOpen(false)} />
      <ModalUseCompass isOpen={compassModalOpen} onClose={() => setCompassModalOpen(false)} />
      <ModalNoCompass isOpen={noCompassModalOpen} onClose={() => setNoCompassModalOpen(false)} />
    </div>
  )
}
